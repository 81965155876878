import type {OttoNexus, QueuableFunction} from "@otto-ec/global-resources/nexus";
import {apps} from "../index";

export type CookieBanner = {
    resurface: QueuableFunction<Resurface>;
}

export type Resurface =
/**
 *
 */
    () => void;

/**
 *
 */
export const cookieBanner: OttoNexus["apps"]["cookieBanner"] = apps.cookieBanner;

export const {
    /**
 *
 */
    resurface
} = cookieBanner;