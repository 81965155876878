import {cookieBanner, type Resurface} from "./index";
import type {FunctionImplementation} from "@otto-ec/global-resources/nexus";
import {AppType, appType} from "../helper";

export const resurface = function () {
    if (appType() == AppType.ANDROID) {
        window.o_apps_android?.onCookieBannerResurface?.()
    } else if (appType() == AppType.IOS) {
        window.webkit?.messageHandlers?.onCookieBannerResurface?.postMessage({});
    }
} satisfies FunctionImplementation<Resurface>

cookieBanner.assignNamespace({resurface})